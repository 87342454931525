.sidebar-nav {
  padding: 2rem 0;
  flex: 1 1 auto;
}

a.sidebar-link {
  padding: .625rem 1.625rem;
  color: #FFF;
  font-size: 0.9rem;
  white-space: nowrap;
  border-left: 3px solid transparent;
  display: grid;
  grid-template-columns: 21px 1fr auto;
  column-gap: 1rem;
}

.sidebar-link i,
.sidebar-link .dropdown-item i {
  font-size: 1.1rem;
  margin-right: .75rem;
}

a.sidebar-link:hover {
  background-color: rgba(255, 255, 255, .075);
  border-left: 3px solid #3b7ddd;
  cursor: pointer;
}

.sidebar-item {
  position: relative;
}

#sidebar {
  z-index: 1000;
  min-width: 268.5px;
}


.sidebar-dropdown {
  padding: .425rem 1.625rem;
  color: #FFF;
  display: block;
  font-size: 0.9rem;
  white-space: nowrap;
  border-left: 3px solid transparent;
  width: 100%;
}

a.user-profile {
  color: #5E6974 !important;
}

.user-profile img {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 10px;
}

.list-responsive .list-header {
  color: #ECF0F1;
  font-weight: bold;
  padding: 0 10px;
}

.list-header .col-item {
  padding: 10px;
}

.list-header .list-body {
  padding: 0 10px 10px 10px;
}

.list-header .row {
  border-bottom: 1px solid #ddd;
}

.list-header .col-item {
  padding: 10px;
}

.list-body .row:hover {
  background-color: #f9f9f9;
}

.form-view .form-control {
  border-color: transparent;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}

.btn-xs {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .75rem;
}

.x_panel {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 17px;
  display: inline-block;
  background: #fff;
  border: 1px solid #E6E9ED;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  transition: all .2s ease;
}

.x_title {
  border-bottom: 2px solid #E6E9ED;
  padding: 1px 5px 6px;
  margin-bottom: 10px;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.x_title .filter {
  width: 40%;
  float: right;
}

.x_title h2 {
  margin: 5px 0 6px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.x_title h2 small {
  margin-left: 10px;
}

.x_title span {
  color: #BDBDBD;
}

.x_content {
  padding: 0 5px 6px;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  margin-top: 5px;
}

.image-movable:hover {
  cursor: move;
}

.chosen {
  background-color: #dadada;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

#simpleList tr {
  cursor: move;
}

.current-page{
  background-color: rgba(255, 255, 255, .075);
  border-left: 3px solid #3b7ddd !important;
}

.moveable{
  cursor: move;
}