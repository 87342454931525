/* jQuery Gridly
 * Copyright 2015 Kevin Sylvestre
 * 1.2.9
 */
.popover {
    max-width: 100%;
}

.gridly, .gridly > :not(.dragging) {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; 
}

.gridly .dragging {
    z-index: 800; 
}

.dropzone-manual .dropzone {
    border: 2px dashed #0087F7;
    background: white;
    border-radius: 5px;
    min-height: 300px;
    padding: 90px 0;
    vertical-align: baseline;
    margin-bottom: 20px;
}

.brick.small .brick-image {
    width: 100%;
    height: 150px !important;
    padding: 1px;
    text-align: center;
    margin-top: 20px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}

.dr-act {
    margin: 8px 0px;
}

.dr-title {
    margin-left: 10px;
    font-size: 13px;
    color: #0087F7;
}

.dropzone .dz-preview .dz-error-message {
    top: 150px !important;
}

.brick {
    position: relative;
}

.dr-act {
    position: absolute;
    top: 18px;
    right: 15px;
}

.dr-act i {
    color: #ffffff;
}